/* eslint-disable camelcase */
import { fetchAllEventDocComm, fetchEventSummary, selectSection } from 'actions';
import BackToTopButton from 'components/Button/BackToTopButton';
import Permissions from 'components/Permissions';
import Redacted from 'components/Redacted';
import { NEW_CHEVRON_LEFT } from 'constants/filterIcons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SectionType } from 'reducers/envelopeReview';
import { getCustomerO365Config } from 'selectors/auth';
import { getCommunicationDocumentLoading } from 'selectors/communications';
import {
  getCommunicationsWithEvents,
  getRuleFlips,
  getSelectedSection,
} from 'selectors/envelopeReview';
import { getShowMoreLikeThis } from 'selectors/envelopeView';
import {
  getEventsList,
  getEventSummaryLoading,
  getRawCorrespondence,
  getSingleEvent,
} from 'selectors/events';
import { useSelector } from 'store';
import { CommunicationWithEvents } from 'types';
import logEvent from 'utils/analytics';
import CommunicationContentHeader from './CommunicationContentHeader';
import CommunicationHistoryContent from './CommunicationHistoryContent';
import EnvelopeMessageInfo from './EnvelopeMessageInfo';
import EnvelopeSkeleton from './EnvelopeSkeleton';
import HighlightedSentenceComponent from './HighlightedSentence';
import MoreLikeThis from './MoreLikeThis';
import RenderThread from './RenderThread';

type ComponentProps = {
  communication: CommunicationWithEvents;
};

const CommunicationContent: React.FC<ComponentProps> = ({ communication }) => {
  const dispatch = useDispatch();

  const sections: SectionType[] = ['message', 'history', 'attachment'];

  const filteredEvents = communication.events
    ? communication.events.filter((event) => !event.hidden)
    : [];
  const eventId = filteredEvents && filteredEvents[0] ? filteredEvents[0].uuid : '';
  const event = useSelector((state) => getSingleEvent(state, eventId));
  const events = useSelector(getEventsList);
  const o365Config = useSelector(getCustomerO365Config);
  const teamsContextDepth = o365Config.teams_thread_context_depth || 5;
  const isTeamsComm = event?.communication?.platform === 'o365_teams';
  const loading = useSelector(getEventSummaryLoading);
  const communicationLoading = useSelector(getCommunicationDocumentLoading);

  const rawCorrespondence = useSelector(getRawCorrespondence(eventId));
  const showMoreLikeThis = useSelector(getShowMoreLikeThis);
  const selectedSection = useSelector(getSelectedSection);
  const communications = useSelector(getCommunicationsWithEvents);
  const ruleFlips = useSelector((state) => getRuleFlips(state, communication?.uuid || ''));

  const [raw, setRaw] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (communication.events) {
      communication.events
        .filter((e) => !events.some((ev) => ev.uuid === e.uuid))
        .forEach((e) => {
          if (!e.hidden) dispatch(fetchEventSummary({ eventId: e.uuid }));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, communication.uuid]);

  useEffect(() => {
    if (communications) {
      dispatch(
        fetchAllEventDocComm({
          communications: [...communications, communication],
        })
      );
    }
  }, [dispatch, communications, communication]);

  useEffect(() => {
    if ((contentRef.current?.clientHeight || 0) < (contentRef.current?.scrollHeight || 0)) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [raw, rawCorrespondence, event]);

  const toggleHistory = (): void => {
    if (selectedSection === 'history') {
      dispatch(selectSection('message'));
    } else {
      dispatch(selectSection('history'));
    }
  };

  if (loading || communicationLoading) {
    return <EnvelopeSkeleton />;
  }

  return (
    <div className="relative overflow-y-hidden h-full flex flex-col justify-between">
      <BackToTopButton scrollRef={contentRef} showButton={showButton} rightOffset={30} />
      <div ref={contentRef} className="relative w-full flex flex-col py-4 gap-6 overflow-auto">
        <div className="relative w-full flex flex-col gap-4">
          <div className="relative flex flex-col justify-start gap-4 px-6">
            <CommunicationContentHeader communication={communication} />

            <EnvelopeMessageInfo />

            {isTeamsComm && event.communication?.platform_guid ? (
              <Redacted field="communications.thread_context">
                <div>
                  {raw ? (
                    <RenderThread
                      eventId={eventId}
                      commUuid={event.communication_uuid}
                      commPlatformUuid={event.communication?.platform_guid}
                      contextPadding={teamsContextDepth}
                      authorOnly={false}
                      hideHighlight={!sections.includes(selectedSection)}
                    />
                  ) : (
                    <RenderThread
                      eventId={eventId}
                      commUuid={event.communication_uuid}
                      commPlatformUuid={event.communication?.platform_guid}
                      contextPadding={teamsContextDepth - Math.floor(teamsContextDepth / 2)}
                      authorOnly
                      hideHighlight={!sections.includes(selectedSection)}
                    />
                  )}
                  <button
                    type="button"
                    className="w-56 py-1.5 bg-litlingo-gray-1 rounded text-base font-bold leading-5 focus:outline-none"
                    onClick={(): void => {
                      logEvent('envelope-full-teams-context-click');
                      setRaw(!raw);
                    }}
                  >
                    {raw ? 'Hide Messages' : 'Show Messages'}
                  </button>
                </div>
              </Redacted>
            ) : (
              <Redacted field="communications.body">
                <div className="flex flex-col break-words">
                  <HighlightedSentenceComponent
                    eventId={eventId}
                    commUuid={event?.communication_uuid || ''}
                    fullMessageOptions="HIGHLIGHTED"
                    hideHighlight={!sections.includes(selectedSection)}
                    underlineOnly={selectedSection === 'history'}
                  />
                </div>
              </Redacted>
            )}
          </div>
        </div>
        {communications && communications?.length > 1 && (
          <div className="flex flex-col gap-4 pl-6">
            <div className="flex flex-row gap-2 text-body">
              <button type="button" onClick={toggleHistory} className="focus:outline-none">
                <div
                  className={`transform ${
                    selectedSection === 'history' ? '-rotate-90' : 'rotate-180'
                  }`}
                >
                  {NEW_CHEVRON_LEFT}
                </div>
              </button>
              <span className="text-litlingo-gray-5 italic">
                {`History: ${ruleFlips} high risk matches prevented during message composition`}
              </span>
            </div>
            <div
              className={`w-full h-full flex flex-col overflow-hidden ${
                selectedSection === 'history' ? '' : 'hidden'
              }`}
            >
              <CommunicationHistoryContent />
            </div>
          </div>
        )}
        {!isTeamsComm && (
          <Redacted field="communications.body">
            <div className="px-6 flex flex-col gap-4">
              <button
                type="button"
                className="w-56 py-1.5 bg-litlingo-gray-1 rounded text-base font-bold leading-5 focus:outline-none"
                onClick={(): void => {
                  logEvent('envelope-full-thread-click');
                  setRaw(!raw);
                }}
              >
                {raw ? 'Hide Messages' : 'Show Messages'}
              </button>
            </div>
          </Redacted>
        )}
      </div>
      {showMoreLikeThis && communication.envelope_uuid && (
        <Permissions action="communication_envelopes.more_like_this">
          <div className="relative flex flex-col gap-4">
            <MoreLikeThis envelopeUuid={communication.envelope_uuid} />
          </div>
        </Permissions>
      )}
    </div>
  );
};

export default CommunicationContent;
