import { fetchCustomReportsRequest } from 'actions';
import { GlobalState } from 'reducers';
import { createSelector } from 'reselect';
import type { CustomReport, NormalizedResource, SavedSearch, Selector, UUID, User } from 'types';

export const getCustomReportsLoading: Selector<boolean> = (state) =>
  state.customReports.loading.includes(fetchCustomReportsRequest.toString());

export const getCustomReports: Selector<CustomReport[]> = createSelector(
  [(state: GlobalState): NormalizedResource<CustomReport> => state.customReports.items],
  (customReports) => Object.values(customReports)
);

export const getReport: Selector<CustomReport, [UUID]> = (state, id) =>
  state.customReports.items[id];

export const getCustomReportsTotalCount: Selector<number> = (state) => state.customReports.count;

export const getUserReport =
  (userId: UUID | undefined): Selector<User | null> =>
  ({ customReports }): User | null =>
    userId ? customReports.users[userId] : null;

export const getSearchReport =
  (savedSearchId: UUID | undefined): Selector<SavedSearch | null> =>
  ({ customReports }): SavedSearch | null =>
    savedSearchId ? customReports.searches[savedSearchId] : null;

export const getCustomReportList: Selector<CustomReport[]> = createSelector(
  (state: GlobalState): NormalizedResource<CustomReport> => state.customReports.items,
  (items) => Object.keys(items).map((uuid) => items[uuid])
);
