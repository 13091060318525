import { EnvelopeAction, UUID } from '@litlingo/client';
import { addCommentEnvelope, setCommentError } from 'actions';
import { deleteEnvelopeAction, updateEnvelopeAction } from 'actions/envelopeActions';
import LeavePageModal from 'components/LeavePageModal';
import { NEW_DELETE_ICON, NEW_EDIT_ICON } from 'constants/commonIcons';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUser } from 'selectors/auth';
import { getCommentError } from 'selectors/envelopeReview';
import { getSingleEnvelopeComments } from 'selectors/envelopes';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  setCommentValue: Dispatch<SetStateAction<string>>;
  commentValue: string;
};

const EnvelopeSidebarComments: React.FC<ComponentProps> = ({ setCommentValue, commentValue }) => {
  const dispatch = useDispatch();
  const { envelopeId } = useParams<{ envelopeId: string }>();

  const [hover, setHover] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedComment, setSelectedComment] = useState<EnvelopeAction>();

  const user = useSelector(getUser);
  const commentError = useSelector(getCommentError);
  const comments = useSelector((state) => getSingleEnvelopeComments(state, envelopeId));
  const commentsRef = useRef<HTMLDivElement>(null);

  const { register, getValues, handleSubmit, reset, watch } = useForm<{
    content: string;
    editContent: string;
  }>({ defaultValues: { content: '' } });
  const watchContent = watch('content');

  useEffect(() => {
    if (commentError) {
      commentsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [commentError]);

  const onSubmit = (): void => {
    logEvent('envelope-add-comment');
    const { content } = getValues();
    dispatch(addCommentEnvelope({ envelopeId, value: content }));
    reset();
    setCommentValue('');
    dispatch(setCommentError(false));
  };

  const handleEditComment = (index: number, item: EnvelopeAction): void => {
    setSelectedComment(item);
    setEditingIndex(index);
    setIsEditing(true);
  };

  const handleDeleteCommet = (envActionUuid: UUID): void => {
    dispatch(deleteEnvelopeAction({ envActionUuid, envelopeId }));
  };

  const handleSaveEditComment = (item: EnvelopeAction): void => {
    const { uuid } = item;
    const { editContent } = getValues();
    dispatch(updateEnvelopeAction({ uuid, value: editContent }));
    setIsEditing(false);
  };

  const handleCancelComment = (): void => {
    setIsEditing(false);
  };

  useEffect(() => {
    reset();
  }, [envelopeId, reset]);

  const renderComment = comments
    .filter((comm) => comm.deleted_at === null)
    .sort((c1, c2) => (moment(c1.created_at).isBefore(moment(c2?.created_at)) ? 1 : -1))
    .map((item, index, arr) => {
      const name = item.updated_by?.name || item.created_by?.name;
      const date = item.updated_at || item.created_at;
      const isUpdated = moment
        .duration(moment(item.updated_at).valueOf() - moment(item.created_at).valueOf())
        .asMilliseconds();
      const time = moment(date).format('MMM DD | h:mm a');
      const { value } = item;

      return (
        <div
          key={item.uuid}
          className={`relative flex flex-col gap-1 rounded ${
            (!isEditing || (isEditing && index !== editingIndex)) && 'py-1 pl-1 pr-2'
          }
           ${index % 2 === 0 ? 'bg-litlingo-gray-0.5' : 'bg-litlingo-gray-1'} ${
            index !== arr.length - 1 ? 'mb-1' : ''
          } `}
          style={{
            filter:
              hover && hoveredIndex === index ? 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5))' : '',
          }}
          onMouseEnter={(): void => {
            setHoveredIndex(index);
            setHover(true);
          }}
          onMouseLeave={(): void => setHover(false)}
        >
          {hover && hoveredIndex === index && !isEditing && item.created_by_uuid === user.uuid && (
            <div
              className="absolute flex flex-row w-full h-full rounded top-0 left-0"
              /* style={{
                marginLeft: '-4px',
                marginTop: '-4px',
              }} */
            >
              <div className="flex flex-row absolute right-1 top-1 gap-x-1">
                <button
                  type="button"
                  className="flex-none  bg-litlingo-gray-4 rounded px-1 w-5 h-5 focus:outline-none hover:bg-litlingo-gray-5"
                  onClick={(): void => handleEditComment(index, item)}
                >
                  <div>{NEW_EDIT_ICON('text-litlingo-white')}</div>
                </button>

                <button
                  type="button"
                  className="flex-none  bg-litlingo-gray-4 rounded px-1 w-5 h-5 focus:outline-none hover:bg-litlingo-gray-5"
                  onClick={(): void => handleDeleteCommet(item.uuid)}
                >
                  <div>{NEW_DELETE_ICON('text-litlingo-white')}</div>
                </button>
              </div>
            </div>
          )}
          {isEditing && index === editingIndex && (
            <div className="relative h-full">
              <div className="flex flex-col">
                <textarea
                  id="editContent"
                  name="editContent"
                  className="relative w-full h-full  comment-textarea flex-1 p-2 text-body rounded resize-none border border-litlingo-gray-2"
                  defaultValue={selectedComment?.value ?? ''}
                  ref={register({ required: true, pattern: /\S/i })}
                />
              </div>
              <div className="absolute bottom-1 right-1 flex flex-row gap-x-1">
                <button
                  type="button"
                  className="button flex justify-center text-base leading-5 font-bold text-litlingo-gray-5 bg-white border-litlingo-gray-3 w-19 h-6 text-center"
                  onClick={handleCancelComment}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="button button--primary flex justify-center text-base leading-5 font-bold text-white w-16 h-6"
                  onClick={(): void => handleSaveEditComment(item)}
                >
                  Save
                </button>
              </div>
            </div>
          )}
          {(!isEditing || (isEditing && index !== editingIndex)) && (
            <>
              <div className="flex flex-row text-base text-litlingo-gray-6 font-normal leading-5">
                <span>{value}</span>
                {isUpdated !== 0 && <span className="text-litlingo-gray-4">&nbsp;(edited)</span>}
              </div>
              <div className="flex justify-end text-xss text-litlingo-gray-6 font-normal leading-4">
                <div>{`${name} on ${time}`}</div>
              </div>
            </>
          )}
        </div>
      );
    });

  const handleAddComment = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const handleChangeComment = (): void => {
    const { content } = getValues();
    setCommentValue(content);
  };

  const navigateAction = (): void => {
    logEvent('envelope-add-comment');
    const { content } = getValues();
    dispatch(addCommentEnvelope({ envelopeId, value: content }));
  };

  const addCommentSection = (
    <div ref={commentsRef} className="flex flex-col gap-2 pl-3 pr-4">
      <textarea
        id="content"
        name="content"
        ref={register({ required: true, pattern: /\S/i })}
        rows={3}
        className={`form-input comment-textarea flex-1 p-2 text-body rounded resize-none border ${
          commentError ? 'form-input-error' : 'form-input'
        } `}
        onKeyPress={handleAddComment}
        placeholder={`Hit 'Enter' to save`}
        onChange={handleChangeComment}
      />
      {commentError && (
        <div className="flex flex-row gap-1 items-center text-body">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842861 3.84344 0 5.87827 0 8C0 10.1217 0.842861 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1216 15.9996 12.1562 15.1567 13.6564 13.6564C15.1567 12.1562 15.9996 10.1216 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM11.1743 10.578C11.2529 10.6568 11.297 10.7635 11.297 10.8747C11.297 10.986 11.2529 11.0927 11.1743 11.1714C11.0956 11.2502 10.9889 11.2945 10.8775 11.2946C10.7662 11.2945 10.6595 11.2502 10.5808 11.1714L8.0056 8.59622L5.42898 11.1714C5.35031 11.2502 5.24359 11.2945 5.13227 11.2946C5.02095 11.2945 4.91422 11.2502 4.83556 11.1714C4.75693 11.0927 4.71276 10.986 4.71276 10.8747C4.71276 10.7635 4.75693 10.6568 4.83556 10.578L7.41218 8.0028L4.83556 5.42617C4.75693 5.34745 4.71276 5.24073 4.71276 5.12946C4.71276 5.0182 4.75693 4.91148 4.83556 4.83275C4.91428 4.75412 5.021 4.70996 5.13227 4.70996C5.24353 4.70996 5.35025 4.75412 5.42898 4.83275L8.0056 7.40938L10.5808 4.83275C10.6596 4.75412 10.7663 4.70996 10.8775 4.70996C10.9888 4.70996 11.0955 4.75412 11.1743 4.83275C11.2529 4.91148 11.297 5.0182 11.297 5.12946C11.297 5.24073 11.2529 5.34745 11.1743 5.42617L8.59902 8.0028L11.1743 10.578Z"
              fill="#F8351A"
            />
          </svg>
          <span>An escalated status requires a comment</span>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className="flex flex-col py-3 bg-white border border-litlingo-gray-1">
        {addCommentSection}

        <div className="flex flex-col py-2 gap-1 overflow-y-auto litlingo-envelope-comment-box pl-3 pr-4">
          {comments.length > 0 ? (
            renderComment
          ) : (
            <span className="text text--large">There are no comments yet</span>
          )}
        </div>
      </div>
      <LeavePageModal
        shouldBlockNavigation={!!watchContent && !!commentValue}
        title="Save Comment?"
        text="Do you want to save your comment before moving to the next message?"
        navigateButtontext="No, Continue"
        cancelOrActionNavigateButtontext="Yes, Save Comment"
        navigateAction={navigateAction}
      />
    </>
  );
};

export default EnvelopeSidebarComments;
