/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-escape */
import { andFilters } from 'constants/filtersMappingValues';
import { RouteParams } from 'types';
import { v4 } from 'uuid';
import { getResource } from './routes';

const SPECIALLY_PARSED_FIELDS = ['broad_search', 'subject'];

export type Tree = {
  op: string;
  data: DataNode[];
  id: string;
};

export type DataNode = {
  op: string;
  field?: string;
  data: Array<DataNode | ValueNode>;
  parent: string;
  group?: string | null;
  id: string;
  raw?: string;
};

export type ValueNode = {
  op: string;
  value: string;
  parent: string;
  group?: string | null;
  label?: string;
  id: string;
};

export enum Condition {
  AND = 'and',
  OR = 'or',
}

export enum Operator {
  FIELD = 'field',
  WORD = 'word',
  EXACT_MATCH = 'exact_match',
  AND = 'and',
  OR = 'or',
  GROUP = 'group',
  NOT = 'not',
}

export function traverseTree(
  tree: Tree | DataNode | ValueNode,
  id: string
): DataNode | ValueNode | null {
  if (tree.id === id || ('value' in tree && tree.value === id)) {
    return tree as DataNode;
  }
  if ('field' in tree && tree.field && SPECIALLY_PARSED_FIELDS.includes(tree.field)) {
    return null;
  }
  if ('data' in tree && tree.op !== Operator.WORD && Array.isArray(tree.data)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const subTree of tree.data) {
      const result = traverseTree(subTree, id);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export function foundParent(tree: Tree | DataNode, id: string): DataNode | Tree | null {
  if ('data' in tree && Array.isArray(tree.data) && tree.op !== Operator.WORD) {
    if ('field' in tree && tree.field && SPECIALLY_PARSED_FIELDS.includes(tree.field)) {
      return null;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const subTree of tree.data) {
      if ('id' in subTree && subTree.id === id) {
        return tree;
      }
      if ('data' in subTree) {
        const result = foundParent(subTree, id);
        if (result) {
          return result;
        }
      }
    }
  }
  return null;
}
type NodesWithoutParent = Omit<DataNode | ValueNode, 'parent'>;
export function addConditionalNode(
  condition: string,
  parent: DataNode,
  nodes: NodesWithoutParent[],
  group?: string
): DataNode {
  const id = v4();
  const conditionalNode: DataNode = {
    op: condition,
    id,
    parent: parent.id,
    group: group ?? null,
    data: nodes.map((node) => ({ ...node, parent: id, group: group ?? null })) as ValueNode[],
  };
  return conditionalNode;
}

export function getConditionalNode(field: string): string {
  let condition = Condition.OR;

  if (!andFilters.includes(`${field}_and`)) {
    condition = Condition.OR;
  }

  return condition;
}

export function addToGroup(tree: Tree, nodes: ValueNode[]): Tree {
  const id = v4();
  const parent = traverseTree(tree, nodes[0].parent) as DataNode;
  if (!parent) {
    throw new Error('Parent not found');
  }
  const groupNode: DataNode = {
    op: 'group',
    id,
    parent: parent.id,
    data: [],
  };

  const conditionalNode = addConditionalNode(Condition.AND, groupNode, nodes, id);
  const data = (parent.data as DataNode[]).filter((node) => !nodes.some((n) => n.id === node.id));
  if (nodes.length <= 1) {
    throw new Error('Cannot add only one value to group');
  }
  if (data.length === 0) {
    throw new Error('Cannot leave empty field');
  }
  groupNode.data = [conditionalNode];
  parent.data = [...data, groupNode];
  return tree;
}

export function addFieldToGroup(tree: Tree, nodes: DataNode[]): Tree {
  const id = v4();
  const parent = traverseTree(tree, nodes[0].parent) as DataNode;
  if (!parent) {
    throw new Error('Parent not found');
  }

  const groupNode: DataNode = {
    op: 'group',
    id,
    parent: parent.id,
    data: [],
  };

  const conditionalNode = addConditionalNode(Condition.AND, groupNode, nodes, id);

  const data = (parent.data as DataNode[]).filter((node) => !nodes.some((n) => n.id === node.id));

  if (nodes.length <= 1) {
    throw new Error('Cannot add only one field to group');
  }

  groupNode.data = [conditionalNode];
  parent.data = [...data, groupNode];

  return tree;
}

export function addValueToExistingGroup(tree: Tree, nodes: ValueNode[], group: DataNode): Tree {
  const newTree = { ...tree };
  nodes.forEach((node) => {
    const parent = foundParent(newTree, node.id);
    if (parent) {
      const data = (parent.data as DataNode[]).filter((n) => n.id !== node.id);
      if (data.length === 0) {
        throw new Error('Cannot leave empty field');
      }
      parent.data = data;
    }
  });
  group.data = [...group.data, ...nodes];
  return newTree;
}

export function lookForField(tree: Tree | DataNode, field: string): DataNode | null {
  if (tree.op === 'field') {
    const fieldNode = tree as DataNode;
    if (fieldNode.field === field) {
      return fieldNode;
    }
  }
  if (Array.isArray(tree.data)) {
    const data = tree.data as DataNode[];
    // eslint-disable-next-line no-restricted-syntax
    for (const subTree of data) {
      const result = lookForField(subTree, field);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export function lookAllForField(tree: Tree | DataNode, field: string): string[] {
  const data = lookForField(tree, field);
  if (!data) return [];

  const values: string[] = [];

  const lookForValues = (node: DataNode | ValueNode): void => {
    if ('data' in node && node.op !== Operator.WORD && Array.isArray(node.data)) {
      node.data.forEach((d) => {
        if ('value' in d) {
          values.push(d.value);
          return;
        }
        lookForValues(d);
      });
    }
  };

  lookForValues(data);

  return values;
}

export function removeParent(tree: Tree, parent: DataNode): void {
  const grandParent = traverseTree(tree, parent.parent) as DataNode;
  if (grandParent) {
    const data = grandParent.data.filter((node) => node.id !== parent.id);
    grandParent.data = data;
    if (grandParent.data.length === 0 && grandParent.parent) {
      removeParent(tree, grandParent as DataNode);
    }
  } else {
    // this is the root of the tree
    tree.op = Operator.AND;
    tree.data = [];
  }
}

export function removeField(tree: Tree, field: string): Tree {
  const fieldNode = lookForField(tree, field);
  if (fieldNode) {
    const parent = foundParent(tree, fieldNode.id);
    if (parent) {
      const data = (parent.data as DataNode[]).filter((node) => node.id !== fieldNode.id);
      parent.data = data;
      if (parent.data.length === 0) {
        removeParent(tree, parent as DataNode);
      }
    } else {
      // this is the root of the tree
      return {
        op: Operator.AND,
        id: v4(),
        data: [],
      };
    }
  }
  return tree;
}

export function removeFields(tree: Tree, fields: string[]): Tree {
  fields.forEach((field) => {
    const fieldNode = lookForField(tree, field);
    if (fieldNode) {
      const parent = foundParent(tree, fieldNode.id);
      if (parent) {
        const data = (parent.data as DataNode[]).filter((node) => node.id !== fieldNode.id);
        parent.data = data;
        if (parent.data.length === 0) {
          removeParent(tree, parent as DataNode);
        }
      }
    }
  });
  return tree;
}

export function removeValue(tree: Tree, value: string, field?: string): Tree {
  if (field) {
    if (SPECIALLY_PARSED_FIELDS.includes(field)) {
      removeField(tree, field);
    }

    const fieldNode = lookForField(tree, field);

    if (fieldNode) {
      const nodeValue = traverseTree(fieldNode, value);
      if (!nodeValue) return tree;
      const valueNode = nodeValue as ValueNode;
      const parent = traverseTree(tree, valueNode.parent) as DataNode;
      if (!parent) return tree;
      const data = parent.data.filter((node) => (node as ValueNode).value !== value);
      parent.data = data;
      if (parent.data.length === 0) {
        removeParent(tree, parent);
      }
    }
  } else {
    const nodeFounded = traverseTree(tree, value);
    if (nodeFounded) {
      const parent = traverseTree(tree, nodeFounded.parent) as DataNode;
      if (parent) {
        const data = parent.data.filter((node) => (node as ValueNode).value !== value);
        parent.data = data;
        if (parent.data.length === 0) {
          removeParent(tree, parent);
        }
      }
    }
  }
  return tree;
}

export function setNewValue(
  tree: Tree | DataNode,
  field: string,
  value: string,
  label?: string,
  isSingleValue?: boolean,
  newFieldConditional = Condition.AND
): Tree {
  if (isSingleValue) {
    const fieldNode = lookForField(tree, field);
    if (fieldNode) {
      const parent = foundParent(tree, fieldNode.id);
      if (!parent) {
        tree.op = Operator.AND;
        tree.data = [];
        (tree as DataNode).field = undefined;
      } else {
        parent.data = parent.data.filter((node) => node.id !== fieldNode.id);
      }
    }
  }

  const fieldNode = lookForField(tree, field);
  const id = v4();
  if (fieldNode) {
    const child = fieldNode.data.find((node) => node.op === 'and' || node.op === 'or');
    if (child) {
      const childNode = child as DataNode;
      const data: ValueNode = {
        op: 'word',
        id,
        value,
        group: null,
        parent: child.id,
        label: label ?? undefined,
      };
      childNode.data = [
        ...childNode.data.filter((n) => !('value' in n) || n.value !== data.value),
        data,
      ];
    } else if (fieldNode.data.length === 1 && fieldNode.data[0].op === Operator.WORD) {
      const conditionalNode = addConditionalNode(getConditionalNode(field), fieldNode, [
        ...(fieldNode.data as ValueNode[]),
        {
          op: 'word',
          id: v4(),
          // @ts-ignore
          value,
          label: label ?? undefined,
          group: null,
        },
      ]);

      fieldNode.data = [conditionalNode];
    }
    return tree as Tree;
  }
  const newField: DataNode = {
    op: 'field',
    id,
    field,
    parent: tree.id,
    data: [],
  };
  const conditionalNode = addConditionalNode(getConditionalNode(field), newField, [
    {
      op: 'word',
      id: v4(),
      // @ts-ignore
      value,
      group: null,
      label: label ?? undefined,
    },
  ]);

  newField.data = [conditionalNode];
  if (tree.op === Operator.FIELD) {
    const lastField = tree as DataNode;
    const idCondition = v4();
    lastField.parent = idCondition;
    newField.parent = idCondition;
    const conditionParent: Tree = {
      op: newFieldConditional,
      id: idCondition,
      data: [lastField, newField],
    };
    return conditionParent;
  }

  if (tree.op === Operator.GROUP) {
    const lastField = tree as DataNode;
    const idCondition = v4();
    lastField.parent = idCondition;
    newField.parent = idCondition;
    const conditionParent: Tree = {
      op: newFieldConditional,
      id: idCondition,
      data: [lastField, newField],
    };
    return conditionParent;
  }

  tree.data = [...tree.data, newField];
  return tree as Tree;
}

export function removeFromGroup(tree: Tree, values: ValueNode[]): Tree {
  const parent = traverseTree(tree, values[0].parent) as DataNode;
  if (parent) {
    const groupParent = foundParent(tree, parent.group ?? '') as DataNode;
    if (groupParent) {
      const data = parent.data.filter((node) => !values.some((n) => n.id === node.id));
      parent.data = data;
      let newValues: ValueNode[] = values;
      if (parent.data.length <= 1) {
        newValues = [...(parent.data as ValueNode[]), ...values];
      }
      const dataGroup = groupParent.data.find((s) => s.group);
      groupParent.data = [
        ...groupParent.data,
        ...newValues.map((v) => ({
          ...v,
          parent: groupParent.id,
          group: dataGroup?.group ?? null,
        })),
      ];
    }
    if (parent.data.length <= 1) {
      removeParent(tree, parent);
    }
  }
  return tree;
}

export function removeFieldFromGroup(tree: Tree, values: DataNode[]): Tree {
  const parent = traverseTree(tree, values[0].parent) as DataNode;
  if (parent) {
    const data = parent.data.filter((node) => !values.some((n) => n.id === node.id));
    parent.data = data;

    let newValues: DataNode[] = values;

    if (parent.data.length <= 1) {
      newValues = [...(parent.data as DataNode[]), ...values];
    }

    const groupParent = foundParent(tree, parent.group ?? '') as DataNode;
    if (groupParent) {
      const dataGroup = groupParent.data.find((s) => s.group);
      groupParent.data = [
        ...groupParent.data,
        ...newValues.map((v) => ({
          ...v,
          parent: groupParent.id,
          group: dataGroup?.group ?? null,
        })),
      ];
    } else {
      const idCondition = v4();

      const group = foundParent(tree, parent.id ?? '') as DataNode;
      group.parent = idCondition;

      const conditionParent: Tree = {
        op: Condition.AND,
        id: idCondition,
        data: [
          ...newValues.map((v) => ({
            ...v,
            parent: idCondition,
            group: null,
          })),
          group,
        ],
      };

      if (parent.data.length <= 1) {
        removeParent(conditionParent, group);
      }

      return conditionParent;
    }

    if (parent.data.length <= 1) {
      removeParent(tree, parent);
    }
  }
  return tree;
}

export function changeConditional(tree: Tree, node: DataNode, condition: Condition): Tree {
  const nodeConditional = traverseTree(tree, node.id);
  if (nodeConditional) {
    nodeConditional.op = condition;
  }
  return tree;
}

export function changeConditionalByField(tree: Tree, field: string, condition: Condition): Tree {
  const fieldNode = lookForField(tree, field);
  if (fieldNode) {
    const conditionalNode = fieldNode.data.find(
      (node) => node.op === Operator.AND || node.op === Operator.OR
    );
    if (conditionalNode) {
      conditionalNode.op = condition;
    }
  }
  return tree;
}

function foundWordFromField(tree: DataNode | ValueNode): string {
  if (tree.op === Operator.WORD) {
    const word = tree as ValueNode;
    return word.value;
  }
  const t = tree as DataNode;
  for (let i = 0; i < t.data.length; i += 1) {
    const word = foundWordFromField(t.data[i]);
    if (word) {
      return word;
    }
  }
  return '';
}

export function transformToString(obj: Tree | DataNode | ValueNode, beforeSend?: boolean): string {
  if (obj.op === 'and' || obj.op === 'or') {
    const tree = obj as DataNode;
    const subQueries = tree.data.map((subObj) => transformToString(subObj, beforeSend));
    const separator = obj.op === 'and' ? ' AND ' : ' OR ';
    return subQueries.join(separator);
  }
  if (obj.op === 'group') {
    const tree = obj as DataNode;
    return `(${transformToString(tree.data[0], beforeSend)})`;
  }
  if (obj.op === 'field') {
    const tree = obj as DataNode;
    const fieldName = tree.field;
    if (tree.field === 'broad_search' && beforeSend) {
      const word = foundWordFromField(tree);
      return word;
    }
    if (tree.field && SPECIALLY_PARSED_FIELDS.includes(tree.field) && tree.raw) {
      const word = tree.raw;
      return word;
    }

    const subFieldQueries = tree.data.map((subObj) => transformToString(subObj, beforeSend));
    return `~${fieldName?.replace('_and', '')}{${subFieldQueries}}`;
  }
  const valueNode = obj as ValueNode;
  return valueNode.value.toString() || '';
}

export function addLabelToNode(tree: Tree, label: string, value: string, field?: string): Tree {
  if (field) {
    const fieldNode = lookForField(tree, field);
    if (fieldNode) {
      const nodeValue = traverseTree(fieldNode, value);
      if (nodeValue) {
        const valueNode = nodeValue as ValueNode;
        valueNode.label = label;
        return tree;
      }
    }
  }
  const node = traverseTree(tree, value);
  if (node && !field) {
    const nodeValue = node as ValueNode;
    nodeValue.label = label;
  }
  if (!node && field) {
    const fnId = v4();
    const fieldNode: DataNode = {
      op: Operator.FIELD,
      id: fnId,
      field,
      parent: tree.id,
      data: [
        {
          op: Operator.WORD,
          id: v4(),
          value,
          group: null,
          label,
          parent: fnId,
        },
      ] as ValueNode[],
    };
    if (tree.op === Operator.FIELD) {
      const lastField = tree as DataNode;
      const idCondition = v4();
      lastField.parent = idCondition;
      fieldNode.parent = idCondition;
      const conditionParent: Tree = {
        op: Condition.AND,
        id: idCondition,
        data: [lastField, fieldNode],
      };
      return conditionParent;
    }
    tree.data = [...tree.data, fieldNode];
  }

  return tree;
}

export function cleanBroadSearch(tree: DataNode, navParams: RouteParams, isSubject: boolean): void {
  if (tree.data[0].op !== Operator.WORD) {
    tree.data = [
      {
        op: Operator.WORD,
        value: isSubject ? (navParams.subject as string) : (navParams.broad_search as string),
        id: v4(),
        parent: tree.id,
        group: null,
        label: isSubject ? (navParams.subject as string) : (navParams.broad_search as string),
      },
    ];
  } else {
    tree.data = tree.data.map((node) => ({
      ...node,
      parent: tree.id,
      id: v4(),
      group: null,
      label: (node as ValueNode).value,
    }));
  }
}

function flattenTree(node: DataNode | ValueNode): DataNode | ValueNode {
  if (node.op === Operator.WORD || typeof node === 'string') {
    return node;
  }
  const dataNode = node as DataNode;
  const flattenedChildren: (ValueNode | DataNode)[] = [];
  for (const child of dataNode.data) {
    const flattenedChild = flattenTree(child);
    if (flattenedChild.op === node.op) {
      const flattenedChildData = flattenedChild as DataNode;
      const nodes = flattenedChildData.data;
      flattenedChildren.push(...nodes);
    } else {
      flattenedChildren.push(flattenedChild);
    }
  }
  dataNode.data = flattenedChildren;
  return dataNode;
}

export function fillDefaultTree(
  tree: Tree,
  navParams?: RouteParams,
  groupId?: string,
  parentId?: string,
  firsTime = true
): Tree {
  const id = v4();
  tree.id = id;

  if (firsTime) {
    if (tree.op !== Operator.AND && tree.op !== Operator.OR && tree.data.length <= 1) {
      if (tree.op === Operator.FIELD) {
        if (!['subjects'].includes((tree as DataNode).field ?? '')) {
          const dataTree = tree as DataNode;
          dataTree.data.forEach((node) => {
            fillDefaultTree(node as Tree, navParams, groupId, id, false);
          });
          const newTree: Tree = {
            op: Operator.AND,
            id: v4(),
            data: [dataTree],
          };
          dataTree.parent = newTree.id;
          return newTree;
        }
      }
    }
  }

  if (groupId) {
    (tree as ValueNode | DataNode).group = groupId;
  }
  if (parentId) {
    (tree as ValueNode | DataNode).parent = parentId;
  }

  if (tree.op === Operator.GROUP) {
    const group = tree as DataNode;
    group.data.forEach((node) => {
      fillDefaultTree(node as Tree, navParams, tree.id, id, false);
    });
  }

  if (tree.op === Operator.AND || tree.op === Operator.OR) {
    const conditional = flattenTree(tree as DataNode) as DataNode;

    conditional.data.forEach((node) => {
      fillDefaultTree(node as Tree, navParams, groupId, id, false);
    });
  }

  if (tree.op === Operator.FIELD) {
    const field = tree as DataNode;
    if (
      (field.field === 'broad_search' || field.field === 'subject' || field.field === 'subjects') &&
      navParams
    ) {
      if (field.field === 'subjects') field.field = 'subject';
      cleanBroadSearch(field, navParams, field.field === 'subject');
    } else {
      field.data.forEach((node) => {
        fillDefaultTree(node as Tree, navParams, groupId, id, false);
      });
    }
  }
  return tree;
}

export function addSearchValue(tree: Tree, value: string, idValue?: string): Tree {
  if (idValue) {
    const node = traverseTree(tree, idValue);
    if (node) {
      const nodeValue = node as ValueNode;
      nodeValue.value = value;
    }
    return tree;
  }
  const id = v4();
  const node: ValueNode = {
    op: 'word',
    id,
    value,
    parent: tree.id,
    group: null,
  };
  tree.data = [...tree.data, node as unknown as DataNode];
  return tree;
}

const getParentField = (tree: Tree, node: ValueNode | DataNode | Tree): DataNode | null => {
  if ('parent' in node) {
    const parent = traverseTree(tree, node.parent);
    if (parent) {
      if (parent.op === Operator.FIELD) {
        return parent as DataNode;
      }
      return getParentField(tree, parent);
    }
  }
  return null;
};
export function findFieldFromValue(tree: Tree, value: string): string {
  const node = traverseTree(tree, value);
  if (!node) return '';
  const parent = getParentField(tree, node);
  return parent?.field ?? '';
}

export function buildFSFromParams(params: RouteParams): string {
  const strippedParams = Object.entries(params).filter(([key]) => {
    if (
      [
        'created_after',
        'created_before',
        'limit',
        'offset',
        'include_count',
        'order_by',
        'order_desc',
        'matches',
        'has_events',
        'sample_uuid',
        'review_set_edit_uuid',
        'should_return_tree',
        'currentReview',
        'is_first_time_create_review_set',
      ].includes(key)
    ) {
      return false;
    }
    return true;
  });

  let fs = '';
  strippedParams.forEach(([key, value], idx) => {
    let values = '';
    const field = key;
    if (Array.isArray(value)) {
      value.forEach((v, idxValue) => {
        if (key.includes('_and')) {
          values += idxValue === value.length - 1 ? v : `${v} AND `;
        } else {
          values += idxValue === value.length - 1 ? v : `${v} OR `;
        }
      });
    } else {
      values = value;
    }
    fs += idx === strippedParams.length - 1 ? `~${field}{${values}}` : `~${field}{${values}} AND `;
  });
  return fs;
}

export const fillUrlWithFilters = (
  url: URLSearchParams,
  fs: string,
  params: RouteParams
): URLSearchParams => {
  if (params.created_after) {
    url.set('envelopes__created_after', params.created_after as string);
  }
  if (params.created_before) {
    url.set('envelopes__created_before', params.created_before as string);
  }
  if (params.matches) {
    url.delete('envelopes__has_events');
    [...new Set(params.matches as string[])].forEach((item) => {
      url.append('envelopes__has_events', item);
    });
  }
  if (params.has_events && !url.has('envelopes__has_events')) {
    [...new Set(params.has_events as string[])].forEach((item) => {
      url.append('envelopes__has_events', item);
    });
  }
  url.append('envelopes__filters_search', fs);
  return url;
};

export const getNavParamsFromFilters = (fs: string, navParams: RouteParams): RouteParams => {
  const params = { ...navParams };
  const p: Record<string, string[]> = {};
  let actualFilter = '';
  const bsRegex = /(?:\s*)(?:[AND|OR]*)(?:\s*)(~broad_search{.*})/g;
  const bsMatches = fs.match(bsRegex);
  if (bsMatches && bsMatches.length > 0) {
    const bs = bsMatches[0];
    const value = bs.substring(bs.indexOf('{') + 1, bs.indexOf('}'));
    params.broad_search = value;
  }
  const subjectRegex = /(?:\s*)(?:[AND|OR]*)(?:\s*)(~subject{.*})/g;
  const subjectMatches = fs.match(subjectRegex);
  if (subjectMatches && subjectMatches.length > 0) {
    const bs = subjectMatches[0];
    const value = bs.substring(bs.indexOf('{') + 1, bs.indexOf('}'));
    params.subject = value;
  }
  const cleanFs = fs.replace(bsRegex, '').replace(subjectRegex, '');
  if (cleanFs.length === 0) return params;
  cleanFs
    .trim()
    .split(' ')
    .forEach((item) => {
      const i = item.trim();
      if (i.startsWith('~')) {
        actualFilter = i.substring(1, i.indexOf('{'));
        p[actualFilter] = [];
        if (i.endsWith('}')) {
          const value = i.substring(i.indexOf('{') + 1, i.indexOf('}'));
          p[actualFilter].push(value);
        } else {
          const value = i.substring(i.indexOf('{') + 1);
          p[actualFilter].push(value);
        }
      } else if (i !== 'OR' && i !== 'AND' && !i.startsWith('~')) {
        const cleanValue = i.replace(/[(){}]/g, '');
        p[actualFilter]?.push(cleanValue);
      }
    });

  Object.entries(p).forEach(([key, value]) => {
    if (!params[key]) {
      const route = getResource(key);
      if (route) {
        if (route.list) params[key] = value;
        // eslint-disable-next-line prefer-destructuring
        if (!route.list) params[key] = value[0];
      }
    }
  });
  return params;
};

export const navParamsFromTree = (fs: string): RouteParams => {
  const p: Record<string, string[]> = { '': [] };
  let actualFilter = '';

  const bsRegex = /(?:\s*)(?:[AND|OR]*)(?:\s*)(~broad_search{([^{}]*(({[^{}]*)+})*[^{}]*)*})/g;
  const bsMatches = fs.match(bsRegex);
  if (bsMatches && bsMatches.length > 0) {
    const bs = bsMatches[0];
    const value = bs.substring(bs.indexOf('{') + 1, bs.lastIndexOf('}'));
    p.broad_search = [value];
  }
  const subjectRegex = /(?:\s*)(?:[AND|OR]*)(?:\s*)(~subject{([^{}]*(({[^{}]*)+})*[^{}]*)*})/g;
  const subjectMatches = fs.match(subjectRegex);
  if (subjectMatches && subjectMatches.length > 0) {
    const bs = subjectMatches[0];
    const value = bs.substring(bs.indexOf('{') + 1, bs.lastIndexOf('}'));
    p.subject = [value];
  }
  const cleanFs = fs.replace(bsRegex, '').replace(subjectRegex, '');

  cleanFs
    .trim()
    .replaceAll('(', '')
    .replaceAll(')', '')
    .split(' ')
    .forEach((item) => {
      const i = item.trim();
      if (i.startsWith('~')) {
        actualFilter = i.substring(1, i.indexOf('{'));
        p[actualFilter] = [];
        if (i.endsWith('}')) {
          const value = i.substring(i.indexOf('{') + 1, i.indexOf('}'));
          p[actualFilter].push(value);
        } else {
          const value = i.substring(i.indexOf('{') + 1);
          p[actualFilter].push(value);
        }
      } else if (i !== 'OR' && i !== 'AND' && !i.startsWith('~')) {
        const cleanValue = i.replace(/[(){}]/g, '');
        p[actualFilter].push(cleanValue);
      }
    });

  return p;
};

export function addAllValuesToField(
  tree: Tree,
  nodes: { value: string; label?: string }[],
  fs: string
): Tree {
  const field = lookForField(tree, fs);
  if (field) {
    const fieldNode = field as DataNode;
    const child = fieldNode.data.find((c) => c.op === Operator.OR || c.op === Operator.AND);
    if (child) {
      const valueNodes: ValueNode[] = nodes.map((n) => ({
        parent: child.id,
        op: Operator.WORD,
        value: n.value,
        label: n.label,
        id: v4(),
      }));
      (child as DataNode).data = valueNodes;
    } else {
      const valueNodes: NodesWithoutParent[] = nodes.map((n) => ({
        op: Operator.WORD,
        value: n.value,
        label: n.label,
        id: v4(),
      }));
      const conditional = addConditionalNode(
        getConditionalNode(fs),
        field,
        valueNodes,
        field.group ?? undefined
      );
      field.data = [conditional];
    }
  } else {
    const id = v4();
    const fieldNode: DataNode = {
      parent: tree.id,
      op: Operator.FIELD,
      field: fs,
      data: [],
      id,
    };
    const valueNodes: NodesWithoutParent[] = nodes.map((n) => ({
      op: Operator.WORD,
      value: n.value,
      label: n.label,
      id: v4(),
    }));
    const conditional = addConditionalNode(getConditionalNode(fs), fieldNode, valueNodes);
    fieldNode.data = [conditional];
    tree.data = [...tree.data, fieldNode];
  }
  return tree;
}

export function addDatesToFiltersSearch(params: RouteParams, tree?: Tree): string | null {
  let dateStr = '';
  if (params.created_after) {
    if (dateStr.length > 0) {
      dateStr = `${params.created_after}<>${dateStr}`;
    } else {
      dateStr = `${params.created_after}<>`;
    }
  }
  if (params.created_before) {
    if (!params.created_after) {
      dateStr = `<>${params.created_before}`;
    } else {
      dateStr += `${params.created_before}`;
    }
  }

  if (!dateStr) return null;

  if (!params.filters_search) {
    if (tree?.data.length === 0 && (tree.op === Operator.AND || tree.op === Operator.OR)) {
      return `~date_range{${dateStr}}`;
    }
  }

  const fs = params.filters_search as string;
  const matchesConditionals = fs.match(/(?:~[a-zA-z]+{.*}\s*)(OR|AND)/);

  if (fs.includes('date_range')) return fs;

  if (matchesConditionals && matchesConditionals.length > 1) {
    const conditional = matchesConditionals[1];
    return `${fs} ${conditional} ~date_range{${dateStr}}`;
  }
  if (tree?.data.length && (tree.op === Operator.AND || tree.op === Operator.OR)) {
    return `${fs} ${tree.op === Operator.AND ? 'AND' : 'OR'} ~date_range{${dateStr}}`;
  }
  return `${fs} AND ~date_range{${dateStr}}`;
}

export function getValuesFromDimensions(params: RouteParams): Tree {
  const parent: Tree = {
    id: v4(),
    op: Operator.AND,
    data: [],
  };
  const dimensions = Object.entries(params);

  dimensions.forEach(([key, value]) => {
    if (key.startsWith('envelopes__')) {
      const field = key.replace('envelopes__', '');
      const fieldNode: DataNode = {
        id: v4(),
        op: Operator.FIELD,
        field,
        data: [],
        parent: parent.id,
      };
      if (Array.isArray(value)) {
        const conditional: DataNode = {
          id: v4(),
          op: key.includes('_and') ? Operator.AND : Operator.OR,
          data: [],
          parent: fieldNode.id,
        };
        value.forEach((v) => {
          if (v.length) {
            conditional.data.push({
              id: v4(),
              op: Operator.WORD,
              value: v,
              parent: conditional.id,
            });
          }
        });
        if (conditional.data.length) fieldNode.data.push(conditional);
      } else if (value.length) {
        fieldNode.data.push({
          id: v4(),
          op: Operator.WORD,
          value,
          parent: fieldNode.id,
        });
      }
      if (fieldNode.data.length) parent.data.push(fieldNode);
    }
  });

  return parent;
}

export function allNodesLabeled(tree?: Tree | DataNode | ValueNode): boolean {
  if (!tree) return false;
  const actualNode = tree as DataNode | ValueNode | Tree;

  if (actualNode.op === Operator.WORD && !(actualNode as ValueNode).label) {
    return false;
  }

  const node = actualNode as DataNode;

  if (node.data && Array.isArray(node.data)) {
    const values = node.data.map((n) => {
      if (!allNodesLabeled(n)) {
        return false;
      }
      return true;
    });
    if (values.some((v) => v === false)) return false;
  }

  return true;
}

export function getAllFields(tree: Tree): DataNode[] {
  const fields: DataNode[] = [];

  const lookForFields = (node: Tree | DataNode): void => {
    if ('data' in node && node.op !== Operator.WORD && node.op !== Operator.NOT) {
      node.data.forEach((d) => {
        if ('field' in d) {
          fields.push(d);
          return;
        }
        if (!('value' in d)) lookForFields(d);
      });
    }
  };

  lookForFields(tree);

  return fields;
}

export function getAllFieldsValues(tree: Tree): Record<string, string[]> {
  if (!tree.data) return {};

  const fields = tree.data.filter((d) => d.field);
  const result: Record<string, string[]> = {};

  if (fields.length === 0) return result;

  fields.forEach((f) => {
    if (f.field && !SPECIALLY_PARSED_FIELDS.includes(f.field)) {
      const values: string[] = [];

      const lookForValues = (node: DataNode | ValueNode): void => {
        if ('data' in node && node.op !== Operator.WORD) {
          node.data.forEach((d) => {
            if ('value' in d) {
              values.push(d.value);
              return;
            }
            lookForValues(d);
          });
        }
      };

      lookForValues(f);

      result[f.field] = values;
    }
  });

  return result;
}

export type TreeFieldsValuesWithLabels = Record<string, { value: string; label: string }[]>;

export function getAllFieldsValuesWithLabels(tree: Tree): TreeFieldsValuesWithLabels {
  if (!tree.data) return {};
  const fields = getAllFields(tree);
  const result: TreeFieldsValuesWithLabels = {};

  if (fields.length === 0) return result;

  fields.forEach((f) => {
    if (f.field && !SPECIALLY_PARSED_FIELDS.includes(f.field)) {
      const values: { value: string; label: string }[] = [];

      const lookForValues = (node: DataNode | ValueNode): void => {
        if ('data' in node && node.op !== Operator.WORD) {
          node.data.forEach((d) => {
            if ('value' in d) {
              values.push({ value: d.value, label: d.label || '' });
              return;
            }
            lookForValues(d);
          });
        }
      };

      lookForValues(f);

      result[f.field] = values;
    }
  });

  return result;
}
