/* eslint-disable camelcase */

import { addAndRemoveValuesToTree, showSuccessAlert } from 'actions';
import Modal from 'components/Modal';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getNavParamsFromTree } from 'selectors/nav';
import { useSelector } from 'store';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
};

const AdvancedSearchModal: React.FC<ComponentProps> = ({ isModalOpen, toggleModalOpen }) => {
  const dispatch = useDispatch();

  const { broad_search, subject, attachment_text, uuid } = useSelector(getNavParamsFromTree) as {
    broad_search: string;
    subject: string;
    attachment_text: string;
    uuid: string;
  };

  const { register, getValues, handleSubmit } = useForm<{
    search: string;
    onlySubject: boolean;
    onlyAttachment: boolean;
    onlyUUID: boolean;
  }>({
    defaultValues: {
      search: broad_search || subject || '',
      onlySubject: subject?.length > 0,
      onlyAttachment: attachment_text?.length > 0,
      onlyUUID: uuid?.length > 0,
    },
  });

  const onSubmit = (): void => {
    const { search, onlySubject, onlyAttachment, onlyUUID } = getValues();

    if (search.trim()) {
      if (onlyUUID) {
        dispatch(
          addAndRemoveValuesToTree({
            valueToAdd: {
              field: 'uuid',
              value: search.trim(),
              label: search.trim(),
              isSingleValue: true,
            },
            fieldsToRemove: ['broad_search', 'subject', 'attachment_text'],
          })
        );
        return;
      }

      if (onlySubject && onlyAttachment) {
        dispatch(
          addAndRemoveValuesToTree({
            valueToAdd: {
              field: 'attachment_text',
              value: search.trim(),
              label: search.trim(),
              isSingleValue: true,
            },
            fieldsToRemove: ['broad_search'],
          })
        );
        dispatch(
          addAndRemoveValuesToTree({
            valueToAdd: {
              field: 'subject',
              value: search.trim(),
              label: search.trim(),
              isSingleValue: true,
            },
            fieldsToRemove: ['broad_search'],
          })
        );
      } else if (onlySubject) {
        dispatch(
          addAndRemoveValuesToTree({
            valueToAdd: {
              field: 'subject',
              value: search.trim(),
              label: search.trim(),
              isSingleValue: true,
            },
            fieldsToRemove: ['broad_search', 'attachment_text'],
          })
        );
      } else if (onlyAttachment) {
        dispatch(
          addAndRemoveValuesToTree({
            valueToAdd: {
              field: 'attachment_text',
              value: search.trim(),
              label: search.trim(),
              isSingleValue: true,
            },
            fieldsToRemove: ['broad_search', 'subject'],
          })
        );
      } else {
        dispatch(
          addAndRemoveValuesToTree({
            valueToAdd: {
              field: 'broad_search',
              value: search.trim(),
              label: search.trim(),
              isSingleValue: true,
            },
            fieldsToRemove: ['subject', 'attachment_text'],
          })
        );
      }
      toggleModalOpen();
    }
  };

  const handleSearchButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const handleOpenSyntaxResource = (): void => {
    window.open(
      'https://lit-public-static-content.s3.us-east-2.amazonaws.com/LitLingo+Search+Syntax+(1).pdf',
      '_blank'
    );
  };

  const handleCopy = async (): Promise<void> => {
    const regex = /(?<=")([A-Za-z0-9]+)(?=")/g;
    const text = getValues()
      .search.match(regex)
      ?.reduce((acc, cur, idx) => {
        if (idx === 0) return cur;
        return `${acc}\n${cur}`;
      }, '');

    if (text) {
      await navigator.clipboard.writeText(text);
      dispatch(showSuccessAlert('Terms copied to your clipboard'));
    }
  };

  const renderAdvancedSearch = (
    <div className="pt-2 pb-4 flex flex-col gap-6">
      <div className="flex flex-col items-start gap-1 text-body">
        <span>
          This advanced query allows you to create more complex searches through your data using
          elastic search.
        </span>
        <button
          type="button"
          className="italic text-litlingo-primary underline focus:outline-none"
          onClick={handleOpenSyntaxResource}
        >
          What types of queries are there?
        </button>
      </div>

      <form>
        <textarea
          id="search"
          ref={register()}
          name="search"
          rows={12}
          placeholder="Type your advanced query here."
          className="form-input w-full p-2 text-body rounded-md resize-none placeholder-italic placeholder-litlingo-gray-6 border-litlingo-gray-2"
        />
        <div className="flex flex-row gap-2">
          <label className="flex flex-row items-center gap-1" htmlFor="subject-checkbox">
            <input
              type="checkbox"
              className="form-checkbox litlingo-checkbox"
              name="onlySubject"
              id="subject-checkbox"
              data-testid="subject-checkbox"
              ref={register()}
            />
            <span className="text-small select-none break-all">Only subject</span>
          </label>
          <label className="flex flex-row items-center gap-1" htmlFor="attachment-checkbox">
            <input
              type="checkbox"
              className="form-checkbox litlingo-checkbox"
              name="onlyAttachment"
              id="attachment-checkbox"
              data-testid="attachment-checkbox"
              ref={register()}
            />
            <span className="text-small select-none break-all">Only attachment</span>
          </label>
          <label className="flex flex-row items-center gap-1" htmlFor="uuid-checkbox">
            <input
              type="checkbox"
              className="form-checkbox litlingo-checkbox"
              name="onlyUUID"
              id="uuid-checkbox"
              data-testid="uuid-checkbox"
              ref={register()}
            />
            <span className="text-small select-none break-all">Only UUID</span>
          </label>
          <button type="button" onClick={handleCopy} className="text-small">
            <span className="text-litlingo-primary-120 underline focus:outline-none">
              Copy for Identifier Terms
            </span>
          </button>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderAdvancedSearch}
          title="Advanced Query"
          okButton
          okButtonText="Search"
          okButtonOnClick={handleSearchButton}
          toggleShowModal={toggleModalOpen}
          style={{ width: '900px' }}
        />
      )}
    </>
  );
};

export default AdvancedSearchModal;
