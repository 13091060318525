import {
  createCustomerRequest,
  createNewSandboxRequest,
  exportUsersRequest,
  fetchAllSandboxesRequest,
  fetchCustomersMeRequest,
  fetchCustomersRequest,
  fetchIndexesCommsRequest,
  FetchIndexingStatusPayload,
  fetchIndexingStatusRequest,
  FetchLogMetricsPayload,
  fetchLogMetricsRequest,
  FetchProcessingStatusPayload,
  fetchProcessingStatusRequest,
  fetchSingleCustomerRequest,
  gmailVerifyCredentialsRequest,
  logoutUser,
  o365VerifyCredentialsRequest,
  saveCustomerRequest,
  selectCustomerCloneCommRequest,
  zendeskVerifyCredentialsRequest,
} from 'actions/auth';
import { DEFAULT_ROLES } from 'constants/userRoles';
import { difference } from 'lodash';
import type {
  API,
  Customer,
  CustomerO365Config,
  Dashboard,
  ElasticsearchIndexes,
  ErrorObject,
  MemberInviteConfig,
  ReviewValueConfigType,
  Role,
  Selector,
  User,
  UUID,
} from 'types';

export const getFetchCustomersLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(fetchCustomersRequest.toString());

export const getFetchSingleCustomersLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(fetchSingleCustomerRequest.toString());

export const getSaveCustomerLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(saveCustomerRequest.toString());

export const getCreateCustomerLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(createCustomerRequest.toString());

export const getUser: Selector<User | API.Users.Me> = (state) => state.auth.user;

export const getUserRoles: Selector<Role[]> = (state) => state.auth.user.roles;

export const getCustomer: Selector<Customer> = (state) => state.auth.customer;

export const getUserCustomer: Selector<Customer | null> = (state) =>
  state.auth.user.customer || null;

export const getCustomerDomain: Selector<string | null> = (state) =>
  state.auth.user.customer ? state.auth.user.customer.domain : 'r'; // r is what we send to force a redirect to current domain\

export const getCustomerName: Selector<string | null> = (state) =>
  state.auth.user.customer ? state.auth.user.customer.name : '';

export const getCustomerDashboards: Selector<Dashboard[] | null> = (state) =>
  state.auth.user.customer ? Object.values(state.auth.user.customer.dashboards || {}) : [];

export const getCustomerZendeskDomain: Selector<string | null> = (state) =>
  state.auth.customer ? state.auth.customer.zendesk_domain : null;

export const getCustomerSummary: Selector<Customer['secrets_summary'] | null> = (state) =>
  state.auth.customer && state.auth.customer.secrets_summary
    ? state.auth.customer.secrets_summary
    : null;

export const getCustomerId: Selector<UUID | null> = (state) => {
  // shape of the user obj is slightly different depending on if you get it from `/login` or /verify
  if (state.auth.user.customer && state.auth.user.customer.uuid) {
    return state.auth.user.customer.uuid;
  }

  if ('customer_uuid' in state.auth.user) {
    return state.auth.user.customer_uuid;
  }

  return null;
};

export const getDefaultOutcome: Selector<UUID | null> = (state) => {
  if (state.auth.user.customer && state.auth.user.customer.config) {
    return state.auth.user.customer.config.default_outcome_uuid;
  }
  return null;
};

export const getIsAuthenticated: Selector<boolean> = (state) => state.auth.isAuthenticated;

export const getIsVerifying: Selector<boolean> = (state) => state.auth.isVerifying;

export const getAuthError: Selector<ErrorObject | null> = (state) => state.auth.error;

export const getAuthErrorMessage: Selector<string | null> = (state) => {
  const error = getAuthError(state);
  if (error == null || typeof error === 'string') {
    return error;
  }

  return error.message;
};

export const getAvailableRoles: Selector<string[]> = (state) =>
  state.users.availableRoles.filter((item) => {
    if (item === 'anonymous') {
      return false;
    }
    return true;
  });

export const getSpoofedUserUuid: Selector<string | undefined> = (state) =>
  state.auth.spoofedUserUuid;

export const getSpoofedUserEmail: Selector<string | undefined> = (state) =>
  state.auth.spoofedUserEmail;

export const getCustomersList: Selector<Customer[]> = (state) => state.auth.customerList;

export const getCustomersListNoSandbox: Selector<Customer[]> = (state) =>
  state.auth.customerList.filter((c) => !c.clone_of_customer_uuid);

export const getSelectCustomerList: Selector<Customer[]> = (state) => state.auth.selectCustomerList;

export const getCustomersMeLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(fetchCustomersMeRequest.toString());

export const getCustomersIndexDocument: Selector<boolean> = (state) =>
  // @ts-expect-error Check if this still working
  !!state.auth.user.customer?.config?.elastic.index_documents;

export const getCustomerMemberInviteConfig: Selector<MemberInviteConfig | null | undefined> = (
  state
) => state.auth.user.customer?.config.member_invite_config;

export const getCustomerConfigError: Selector<string | null | undefined> = (state) =>
  state.auth.user.customer?.config?.global_error_message;

export const getCustomersAllowFulTextSearch: Selector<boolean | null | undefined> = (state) =>
  state.auth.user.customer?.config?.elastic.allow_full_text_search;

export const getCustomerZendeskVerifyCredentials: Selector<boolean | null> = (state) =>
  state.auth ? state.auth.zendeskVerifyCredentials : null;

export const getCustomero365VerifyCredentials: Selector<boolean | null> = (state) =>
  state.auth ? state.auth.o365VerifyCredentials : null;

export const getCustomerZendeskVerifyCredentialsLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(zendeskVerifyCredentialsRequest.toString());

export const getCustomerGmailVerifyCredentials: Selector<boolean | null> = (state) =>
  state.auth ? state.auth.gmailVerifyCredentials : null;

export const getCustomerO365VerifyCredentialsLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(o365VerifyCredentialsRequest.toString());

export const getCustomerGmailVerifyCredentialsLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(gmailVerifyCredentialsRequest.toString());

export const getCustomerValidEmailDomains: Selector<string[]> = (state) =>
  state.auth.user.customer?.config.valid_email_domains ?? [];

export const getCustomerO365Config: Selector<CustomerO365Config> = (state) =>
  state.auth.user.customer?.config.o365_config ?? { tenant_id: null, client_id: null };

export const getDisplayShortcutsModal: Selector<boolean | null> = (state) =>
  state.auth.displayShortcutsModal;

export const getCustomersIndexesComm: Selector<ElasticsearchIndexes> = (state) =>
  state.auth.indexes;

export const getLogMetricsLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(fetchLogMetricsRequest.toString());

export const getLogMetrics: Selector<FetchLogMetricsPayload> = (state) => state.auth.logMetrics;

export const getIndexingStatusLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(fetchIndexingStatusRequest.toString());

export const getIndexingStatus: Selector<FetchIndexingStatusPayload> = (state) =>
  state.auth.indexingStatus;

export const getProcessingStatusLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(fetchProcessingStatusRequest.toString());

export const getProcessingStatus: Selector<FetchProcessingStatusPayload> = (state) =>
  state.auth.processingStatus;

export const getCustomersIndexesCommLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(fetchIndexesCommsRequest.toString());

export const getIsOnlySystemAdmin: Selector<boolean> = (state) =>
  difference(state.auth.user.roles, DEFAULT_ROLES).length === 1 &&
  difference(state.auth.user.roles, DEFAULT_ROLES)[0] === 'system-admin';

export const getIsSigningOut: Selector<boolean> = (state) =>
  state.auth.loading.includes(logoutUser.toString());

export const getSandboxes: Selector<Customer[]> = (state) => state.auth.sandboxes;

export const getFetchSandboxesLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(fetchAllSandboxesRequest.toString());

export const getCreateNewSandboxLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(createNewSandboxRequest.toString());

export const getCustomerTagGroupsIds: Selector<string[]> = (state) =>
  state.auth.user.customer ? state.auth.user.customer.config.tag_group_uuids : [];

export const getCustomerFurtherReviewTagGroup: Selector<string> = (state) =>
  state.auth.user.customer ? state.auth.user.customer.config.further_review_tag_group : '';

export const getSurroundingContextRulesIds: Selector<string[]> = (state) =>
  state.auth.user.customer?.config.ui_surrounding_context_rules || [];

export const getSurroundingContextIdentifiersIds: Selector<string[]> = (state) =>
  state.auth.user.customer?.config.ui_surrounding_context_identifiers || [];

export const getReviewValueLabels: Selector<ReviewValueConfigType | null> = (state) =>
  state.auth.user.customer?.config?.review_value_labels || null;

export const getExportUsersLoading: Selector<boolean> = (state) =>
  state.auth.loading.includes(exportUsersRequest.toString());

export const getShowMoreLikeThis: Selector<boolean> = (state) =>
  state.auth.user.customer?.config?.embed_incoming || false;

export const getShowExportEnvelope: Selector<boolean> = (state) =>
  state.auth.user.customer?.config.allow_export_envelope_summary || false;

export const getAllowDeleteDigests: Selector<boolean> = (state) =>
  state.auth.user.customer?.config.delete_associated_digests || false;

export const getSelectSandboxCloneCommunication: Selector<boolean> = (state) =>
  state.auth.loading.includes(selectCustomerCloneCommRequest.toString());

export const getOfficeExtensionEnabled: Selector<boolean> = (state) =>
  state.auth.user.customer?.config.office_extension_config.enabled || false;

export const getUserLicensesNumber: Selector<number> = (state) =>
  state.auth.user.customer?.config.user_licenses || 0;

export const getIsNewCustomer: Selector<boolean> = (state) =>
  state.auth.user.customer?.config.is_new_customer || false;

export const getLowDataMode: Selector<boolean> = (state) =>
  state.auth.user.customer?.config.low_data_mode || false;

export const getUseNewGraphs: Selector<boolean> = (state) =>
  // @ts-ignore
  state.auth?.user?.customer?.config.force_as_dict_v2_docs || false;

export const getRequireComment: Selector<boolean> = (state) =>
  state.auth?.user?.customer?.config.require_comment_escalated || false;
